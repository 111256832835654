import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const roundedImg = {
  borderRadius: "290486px",
}
export default () => (
  <StaticQuery
    query={graphql`
      query {
        review: file(
          relativePath: { eq: "gallery/home/review-jcu-tweddell.jpg" }
        ) {
          id
          childImageSharp {
            fixed(width: 300, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section className="hero is-small is-white">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered is-reversed-mobile">
              <div className="column is-half  is-offset-1-fullhd">
                <div className="section-header">
                  <h1 className="title is-3 is-spaced">
                    Bill Tweddell
                    <span className="is-hidden-touch">
                      <br></br>
                    </span>
                  </h1>
                  <h2 className="subtitle is-5">
                    Chancellor - James Cook University
                  </h2>
                  <p>
                    <i>
                      <span className="is-size-5">"</span>James Cook University
                      has used Mr de Rooy’s services for over 15 years to take
                      the official photographs of the Council, the Chancellor
                      and the Vice Chancellor and has always found him to be
                      thoroughly professional, highly competent and a joy to
                      work with. In 2016, following my appointment as
                      Chancellor, I experienced firsthand Mr de Rooy’s high
                      technical proficiency and great facility for working with
                      people when James Cook University’s official photographs
                      were taken. I recommend him highly.
                      <span className="is-size-5">"</span>
                      <br></br><br></br>
                    </i>
                  </p>
                  <div className="field is-grouped">
                    <div className="control">
                      <Link
                        className="button is-primary is-medium"
                        to="/contact/"
                      >
                        Contact Us
                      </Link>
                    </div>
                    <div className="control"> </div>
                  </div>
                </div>
              </div>
              <div className="column is-half">
                <Img
                  placeholderClassName="is-rounded"
                  className="image is-300x300"
                  imgStyle={roundedImg}
                  fixed={data.review.childImageSharp.fixed}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  />
)
