import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Firstcards from "../components/first-cards"
import Blurb from "../components/blurb"
import Review from "../components/review"
import Secondcards from "../components/second-cards"
import Aipp from "../components/aipp"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Blurb />
    <Firstcards />
    <Review />
    <Secondcards />
    <Aipp />
  </Layout>
)

export default IndexPage
