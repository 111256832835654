import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        commercial: file(
          relativePath: { eq: "gallery/home/commercial-prawn-soup.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 706, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        portraits: file(
          relativePath: { eq: "gallery/home/portraits-family.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 706, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        restoration: file(
          relativePath: { eq: "gallery/home/restoration-army.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 706, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <div className="section">
        <div className="container">
          <div className="columns">
            {/* start commercial */}
            <div className="column is-one-third">
              <Link to="/gallery/commercial">
                <div className="card large rounded">
                  <div className="card-image">
                    <figure className="image">
                      <Img fluid={data.commercial.childImageSharp.fluid} />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <h1 className="has-text-centered">Commercial</h1>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="column is-one-third">
              <Link to="/gallery/portraits">
                <div className="card large rounded">
                  <div className="card-image">
                    <figure className="image">
                      <Img fluid={data.portraits.childImageSharp.fluid} />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <h1 className="has-text-centered">Portraits</h1>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="column is-one-third">
            <Link to="/gallery/restoration">
              <div className="card large rounded">
                <div className="card-image">
                  <figure className="image">
                    <Img fluid={data.restoration.childImageSharp.fluid} />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content">
                    <h1 className="has-text-centered">Restoration</h1>
                  </div>
                </div>
              </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)
