import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        file(
          relativePath: {
            eq: "gallery/home/blurb-kennedy-highway-landscape.jpg"
          }
        ) {
          id
          childImageSharp {
            fluid(maxWidth: 706, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section className="hero is-medium is-white">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-6 is-5-fullhd is-offset-1-fullhd">
                <div className="section-header">
                  <h1 className="title is-spaced is-size-3">
                    Tableland Photography
                    <span className="is-hidden-touch">
                      <br></br>
                    </span>
                  </h1>
                  <h3 className="subtitle is-3 is-size-5">
                    Providing professional photography services to all of North
                    Queensland from our base on the Atherton Tablelands
                    <br />
                    <br />
                    John and his wife Pam have completed photographic projects
                    all over North Queensland for over <strong>
                      30 years
                    </strong>{" "}
                    and have now based their business on the Atherton Tableland
                    near Malanda
                  </h3>
                  <div className="field is-grouped">
                    <div className="control">
                      <Link
                        className="button is-primary is-medium"
                        to="/contact/"
                      >
                        Contact Us
                      </Link>
                    </div>
                    <div className="control"> </div>
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <div className="has-text-centered">
                  <Img
                    className="image-border"
                    fluid={data.file.childImageSharp.fluid}
                  />
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  />
)
