import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        schools: file(
          relativePath: {
            eq: "gallery/home/schools-queensland-police-service.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 706, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        workshops: file(
          relativePath: {
            eq: "gallery/home/workshops-daintree-photography.jpg"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 706, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        landscape: file(
          relativePath: { eq: "gallery/home/landscape-mt-bartle-frere.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 706, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-third">
            <Link to="/gallery/schools">
              <div className="card large rounded">
                <div className="card-image">
                  <figure className="image">
                    <Img fluid={data.schools.childImageSharp.fluid} />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content">
                    <h1 className="has-text-centered">Schools</h1>
                  </div>
                </div>
              </div>
              </Link>
            </div>

            <div className="column is-one-third">
            <Link to="/gallery/workshops">
              <div className="card large rounded">
                <div className="card-image">
                  <figure className="image">
                    <Img fluid={data.workshops.childImageSharp.fluid} />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content">
                    <h1 className="has-text-centered">Workshops</h1>
                  </div>
                </div>
              </div>
              </Link>
            </div>

            <div className="column is-one-third">
              <Link to="/gallery/landscape">
                <div className="card large rounded">
                  <div className="card-image">
                    <figure className="image">
                      <Img fluid={data.landscape.childImageSharp.fluid} />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <h1 className="has-text-centered">Landscape</h1>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)
