import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        aipp: file(relativePath: { eq: "aipp.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 660, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section className="hero is-medium is-white">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-6 is-5-fullhd is-offset-1-fullhd">
                <div className="section-header">
                  <h1 className="title is-spaced is-size-3">
                    Accredited Photographer
                    <span className="is-hidden-touch">
                      <br></br>
                    </span>
                  </h1>
                  <h3 className="subtitle is-3 is-size-5">
                    The Australian Institute of Professional Photography (AIPP)
                    complying with strict government guidelines and requirements
                    accredits photographers. Accredited Photographers have to
                    adhere to a minimum standard, demonstrate their skills by
                    portfolios submission with regular reviews, and similar to
                    other professions they also have to participate in ongoing
                    professional development/training to ensure they stay
                    abreast of technology. Legal requirements are imposed to
                    ensure public liability insurance is in place and the
                    photographer adheres to all business and legal requirements
                    and follows a recognised digital asset management regime.
                  </h3>
                  <div className="field is-grouped">
                    <div className="control">
                      <Link
                        className="button is-primary is-medium"
                        to="/about#aipp"
                      >
                        Find Out More
                      </Link>
                    </div>
                    <div className="control"> </div>
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <div className="image-border">
                  <Img fluid={data.aipp.childImageSharp.fluid} alt="" />
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )}
  />
)

